// import axios from "axios";

const state = {
    cottages: []
};

const getters = {
    allCottages: (state) => state.cottages
};

const actions = {
    async addCottage({ commit }, form) {
        commit('newCottage', form);
    }
};

const mutations = {
    newCottage: (state, cottage) => state.cottages.unshift(cottage)
};

export default {
    state,
    getters,
    actions,
    mutations
}
function lazyLoad(path, view) {
  return () => import(`@/pages/${path}${view}.vue`)
}

const routes = [
  {
    path: "/",
    name: "home",
    component: lazyLoad('', 'Home'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: lazyLoad('', 'About'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/platinum-villa",
    name: "platinum-villa",
    component: lazyLoad('Villa/', 'Platinum'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/cottage",
    name: "cottage",
    component: lazyLoad('Villa/', 'Cottages'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/diamond-villa",
    name: "diamond-villa",
    component: lazyLoad('Villa/', 'Diamond'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/one-day-picnic",
    name: "one-day-picnic",
    component: lazyLoad('', 'OneDayPicnic'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/one-day-picnic-booking/:package_id/:person_type?",
    name: "one-day-picnic-booking",
    component: lazyLoad('', 'OneDayPicnicBooking'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/wedding",
    name: "wedding",
    component: lazyLoad('', 'Wedding'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/event",
    name: "event",
    component: lazyLoad('', 'Event'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/event-booking/:event_id",
    name: "event-booking",
    component: lazyLoad('', "EventBooking"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/events-rio",
    name: "eventatrio",
    component: lazyLoad('', 'EventAtRio'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/photo",
    name: "photo",
    component: lazyLoad('Gallery/', 'Photo'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/video",
    name: "video",
    component: lazyLoad('Gallery/', 'Video'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/contact-us",
    name: "contact-us",
    component: lazyLoad('', 'ContactUs'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/terms-and-condition",
    name: "terms-and-condition",
    component: lazyLoad('', 'TermsCondition'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: lazyLoad('', 'PrivacyPolicy'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/refund-and-return-policy",
    name: "refund-and-return-policy",
    component: lazyLoad('', 'RefundAndReturnPolicy'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/:path(.*)',
    component: lazyLoad('', 'NotFound'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/thanks',
    name: "thanks",
    component: lazyLoad('', 'Thanks'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard/:bookingtype?",
    name: "dashboard",
    component: lazyLoad('User/', 'Dashboard'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order/status",
    name: "order.status",
    component: lazyLoad('User/', 'OrderStatus'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-profile",
    name: "my-profile",
    component: lazyLoad('User/', 'MyProfile'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-booking",
    name: "my-booking",
    component: lazyLoad('User/', 'MyBooking'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking-history",
    name: "booking-history",
    component: lazyLoad('User/', 'BookingHistory'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/booking-pending",
    name: "booking-pending",
    component: lazyLoad('User/', 'BookingPending'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/villa",
    name: "villa",
    component: lazyLoad('User/', 'Villa'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/villa-list",
    name: "villa-list",
    component: lazyLoad('', 'VillaList'),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  },
};
</script>
<style scoped>
.carousel__prev, .carousel__next {
    width: 40px !important;
    height: 40px !important;
    /* background-color: #000 !important; */
    border-radius: 100% !important;
    /* top: 45% !important; */
    z-index: 1;
    display: flex;
}
.carousel__prev svg, .carousel__next svg{
    fill: white;
}
.dp__button{
  display: none !important;
}
.dp__select {
  background-color: #7DA741 !important;
  color: #fff !important;
  padding: 3px 7px 5px 7px !important;
}
.dp__today {
  border: 1px solid #7DA741 !important;
}
.dp__active_date {
  background-color: #7DA741 !important;
  font-weight: bold;
}
.dp__selection_preview {
  color: #7DA741 !important;
  font-weight: bold;
}
.dp__menu  {
  border-radius: 10px !important;
  box-shadow: rgb(0 0 0 / 36%) 2px 18px 88px 4px !important;
  padding: 5px !important;
  margin: 5px  !important;
  visibility: visible;
  transition: top 0.5s linear !important;
  max-height: 400px;
}
.dp__action_row {
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  padding: 5px !important;
  margin: 5px 0;
}
</style>
import { createRouter, createWebHistory } from 'vue-router';
import routes from './components/commonConstant/RoutesConstant';

// const route = [
//     {
//         path: "/",
//         name: "home",
//         component: Home,
//         meta: {
//           requiresAuth: false,
//         },
//     },
//     { path: '*', redirect: '/' }
// ];
// const router = createRouter({
//     history: createWebHistory(),
//     route,
//     routes
// })

// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   const publicPages = ['/'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('auth_cus_token');

//   if (authRequired && !loggedIn) {
//     return next('/');
//   }

//   next();
// })
const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router;

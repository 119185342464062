import { createApp } from 'vue'
import '@/assets/styles/main.css';
import '@/assets/styles/tailwind.css';
import router from './routes';
import Toaster from "@meforma/vue-toaster";

import App from './App.vue'

import store from './store'

// createApp(App).mount('#app')

const app = createApp(App);
app.use(store);
app.use(router);
app.use(Toaster);
app.mount('#app');

export default createApp

// import Vuex from 'vuex';
// import Vue from 'vue';
import { createStore } from "vuex";
import platinum from './modules/platinum';
import diamond from './modules/diamond';
import cottage from './modules/cottage';

//Load vuex
// Vue.use(Vuex);

// Create Store
export default createStore({
// export default new Vuex.Store({
    modules: {
        platinum,
        diamond,
        cottage
    }
});

// import { createStore } from "vuex";

// const store = createStore({
//     state() {
//         return {
//             platinum: [],
//         }
//     },
//     mutations: {
//         setPlatinumData(state, platinum) {
//             state.platinum = platinum;
//             localStorage.setItem("platinum", JSON.stringify(platinum));
//         },
//     },
//     actions: {
//         async createPlatinumData({ commit }, bookData) {
//             commit("setPlatinumData", bookData);
//         },
//     },
//     getters: {
//         PlatinumData(state) {
//             return state.platinum;
//         },
//         platinum: state => state.platinum
//     }
// });

// export default store;
// import axios from "axios";

const state = {
    platinums: []
};

const getters = {
    allPlatinums: (state) => state.platinums
};

const actions = {
    async addPlatinum({ commit }, form) {
        commit('newPlatinum', form);
    }
};

const mutations = {
    newPlatinum: (state, platinum) => state.platinums.unshift(platinum)
};

export default {
    state,
    getters,
    actions,
    mutations
}
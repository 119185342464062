// import axios from "axios";

const state = {
    diamonds: []
};

const getters = {
    allDiamonds: (state) => state.diamonds
};

const actions = {
    async addDiamond({ commit }, form) {
        commit('newDiamond', form);
    }
};

const mutations = {
    newDiamond: (state, diamond) => state.diamonds.unshift(diamond)
};

export default {
    state,
    getters,
    actions,
    mutations
}